<script>
import axios from "axios";
import Vue from "vue";
import sha1 from "sha1";
import { format, parseISO } from "date-fns";
let datos = {
  //servidor: 'http://alherrera.tk:8082/rvrs',
  servidor: "https://app.rvrs.mx:8082/rvrs",
  cdn: "http://45.55.89.128/cdn",
  yo: {},
  WSTcolecciones: ["Producto", "Etiqueta"],
  cache: {},
  usarCache: false,
  modoPersistente: false,
  defaultUser: "rvrs@local",
  defaultPw: "mi vieja mula ya no es lo que era",
  defaultTimeout: 2500,
  modoProduccion: window.location.href.indexOf("localhost") < 0,
};
let metodos = {
  wait: (t = 1000) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, t);
    });
  },
  auth(entidad) {
    console.log("Autentificando", entidad.nombreLargo || entidad.email);
    datos.yo = entidad;
    return null;
  },
  moment: (a = new Date(), b = "") => {
    if (typeof a == "string") a = parseISO(a);
    return format(a, b);
  },
  currency: (a = 0) => {
    const formatter = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(a).replace(/\$/g, "");
  },
  async errorHandler(e) {
    let text = "";
    if (e && e.text) text = ": " + e.text;
    datos.error = "Error" + text;
    await metodos.wait(1000);
    delete datos.error;
    return true;
  },
  guardarLocal(lugar, obj) {
    console.log("Guardando", lugar, obj);
    if (typeof obj == "object")
      localStorage.setItem(lugar, JSON.stringify(obj));
    else localStorage.setItem(lugar, obj);
  },
  obtenerLocal(lugar) {
    try {
      return JSON.parse(localStorage.getItem(lugar));
    } catch (e) {
      console.error("ELOCALSTORAGE", e);
      return localStorage.getItem(lugar);
    }
  },
  borrarLocal(lugar) {
    localStorage.removeItem(lugar);
  },
  esMovil: () => {
    const w = window.innerWidth;
    const h = window.innerHeight;
    const tresholdSize = 1000;
    return w < h || (w < tresholdSize && h < tresholdSize);
  },
  login(user, pass) {
    user = user || "";
    pass = sha1(pass || "");
    console.info("Login", user, pass);
    return new Promise((resolve, reject) => {
      let promises = [];
      const qp1 = axios.post(datos.servidor + "/login", {
        email: user,
        password: pass,
      });
      const qp2 = metodos.find(
        "Directorio",
        ["email,eq," + user, "password,eq," + pass],
        {
          limit: 1,
        }
      );
      qp1
        .then(function (rJWT) {
          console.info(rJWT);
          if (rJWT && rJWT.data && rJWT.data._id && rJWT.data.jwt) {
            console.info("AUTH+JWT", rJWT.data);
            metodos.guardarLocal("wst.yo", rJWT.data);
            resolve([rJWT.data]);
          } else console.error("Credenciales incorrectas JWT");
        })
        .catch(function (e) {
          qp2.then(function (rLeg) {
            if (rLeg && rLeg[0] && rLeg[0]._id) {
              console.info("AUTH+Legacy", rLeg);
              localStorage.setItem("wst.yo", rLeg[0]);
              resolve(rLeg);
            } else {
              console.error("EAUTH");
              reject({
                error: "Error de autentificación",
              });
            }
          });
        });
    });
  },
  uuid() {
    return "xxxxxxxxxxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      let r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
  dump() {
    console.error("Solicitado volcado de BD", new Date());
    return new Promise((resolve, reject) => {
      const ruta = datos.servidor + "/dump";
      axios
        .post(ruta, null, {
          headers: {
            "wst-appkey": "vueonic",
            token: metodos.token(),
          },
        })
        .then(function (p) {
          let o = p.data || {};
          if (!o.error) resolve(o);
          else reject(o);
        });
    });
  },
  token() {
    let t = "";
    try {
      let k = JSON.parse(localStorage.getItem("wst.yo"));
      t = k.jwt || "";
    } catch (e) {
      // No se pudo recuperar el token
    }
    return t;
  },
  get(modelo, id) {
    console.log("GET", modelo, id);
    modelo = modelo || "";
    id = id || "";
    return new Promise((resolve, reject) => {
      const desdeCache = false;
      let ruta = datos.servidor + "/get/" + modelo;
      if (id != "") {
        ruta += "/" + id;
      }
      ruta += "?ts=" + btoa(Math.floor(new Date().getTime() / 1000));
      try {
        const options = {
          headers: {
            "wst-appkey": "vueonic",
            token: metodos.token(),
          },
        };
        if (datos.defaultTimeout > 0 && id?.length > 0)
          options.timeout = datos.defaultTimeout;
        axios.get(ruta, options).then(
          function (o) {
            if (o && o.data) {
              if (!desdeCache) resolve(o.data);
              if (o.data._id) {
                // Guardar en cache el objeto individual
                if (!datos.cache[modelo]) datos.cache[modelo] = {};
                datos.cache[modelo][id] = o.data;
                console.log("Cache app", modelo, o.data._id);
              } else if (
                (o.data.length > 0 &&
                  datos.WSTcolecciones.indexOf(modelo) != -1) || // Colección cacheable
                datos.tiempoCache != 0 // Cache habilitada desde servidor
              ) {
                if (!datos.cache[modelo]) datos.cache[modelo] = {};
                o.data.forEach(function (obj) {
                  if (obj._id) datos.cache[modelo][obj._id] = obj;
                });
                if (datos.usarCache) {
                  console.log(
                    "Actualizando cache en dispositivo",
                    modelo,
                    o.data.length
                  );
                  localStorage.setItem("wst." + modelo, JSON.stringify(o.data));
                }
              }
            } else reject(o);
          },
          function (e) {
            metodos.errorHandler(e);
            reject(e);
          }
        );
        //}
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  },
  find(modelo = "", params = [], adicional = {}) {
    return new Promise((resolve, reject) => {
      let resultado = [];
      let enCache = false;
      let ruta = datos.servidor + "/get/" + modelo + "?";
      if (params.length > 0) {
        params.forEach(function (param) {
          if (typeof param == "string") ruta += "filters[]=" + param + "&";
        });
      }
      if (typeof adicional.limit != "undefined")
        ruta += "limit=" + adicional.limit + "&";
      if (typeof adicional.skip != "undefined")
        ruta += "skip=" + adicional.skip + "&";
      if (typeof adicional.math != "undefined")
        ruta += "math=" + adicional.math + "&";
      if (typeof adicional.sort != "undefined") {
        for (const s in adicional.sort) {
          ruta += "sort=" + adicional.sort[s] + "&";
        }
      }
      // Determinar si la consulta fue hecha previamente
      let candidato = "";
      candidato = localStorage.getItem("wstRuta." + btoa(ruta));
      if (
        candidato &&
        candidato.startsWith("[") &&
        candidato.length > 2 &&
        datos.tiempoCache > 0
      ) {
        enCache = true;
        let listaCache = JSON.parse(candidato);
        console.log("Consulta en caché. Rescatando.", listaCache.length);
        console.log(ruta);
        for (let c in listaCache) {
          let id = listaCache[c];
          if (datos.cache[modelo] && datos.cache[modelo][id])
            resultado.push(datos.cache[modelo][id]);
          else enCache = false;
        }
        console.warn("Cache", resultado);
        if (enCache) resolve(resultado);
      }
      // Hacer la consulta real
      try {
        axios
          .get(ruta, {
            headers: {
              "wst-appkey": "vueonic",
              token: metodos.token(),
            },
          })
          .then(function (o) {
            if (typeof o.data != "undefined") {
              resultado = o.data;
              // Guardar en dispositivo
              if (datos.tiempoCache && datos.tiempoCache > 0) {
                // Respondo a tu llamado, joven elfo
                let res = [];
                // Cache de objetos
                resultado.forEach(function (obj) {
                  res.push(obj._id);
                  metodos.saveCache(modelo, obj);
                });
                // Cache de peticion
                let encRutav = btoa(ruta);
                //localStorage.setItem('wstRuta.' + encRuta, JSON.stringify(res));
              }
              // Ya
              if (!enCache) resolve(resultado);
            } else reject(o);
          })
          .catch(function (e) {
            reject(e);
          });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  },
  save(modelo, objeto) {
    console.log("Save", modelo, objeto);
    modelo = modelo || "";
    objeto = objeto || null;
    if (typeof objeto.__v != "undefined") delete objeto.__v;
    return new Promise((resolve, reject) => {
      if (objeto == null || modelo == "")
        reject("Debes especificar un modelo y un objeto a actualizar");
      let ruta = datos.servidor + "/upsert/" + modelo;
      if (!datos.modoPersistente) {
        // Guardado normal
        if (!objeto._id) {
          // Insertar
          axios
            .post(ruta, objeto, {
              headers: {
                "wst-appkey": "vueonic",
                token: metodos.token(),
              },
            })
            .then(
              function (o) {
                console.log("Insertado", o.data.objeto);
                metodos.saveCache(modelo, o.data.objeto);
                resolve(o.data.objeto);
              },
              function (e) {
                metodos.errorHandler(e);
                reject(e);
              }
            );
        } else {
          // Actualizar
          ruta += "/" + objeto._id;
          axios
            .put(ruta, objeto, {
              headers: {
                "wst-appkey": "vueonic",
                token: metodos.token(),
              },
            })
            .then(
              function (o) {
                console.log("Actualizado", o.data);
                metodos.saveCache(modelo, o.data.objeto);
                resolve(o.data.objeto);
              },
              function (e) {
                metodos.errorHandler(e);
                reject(e);
              }
            );
        }
      } else {
        // Guardado persistente
        console.log("Guardado persistente", modelo);
        if (!datos.cache[modelo]) {
          console.warn("Creando cache de objetos", modelo);
          datos.cache[modelo] = {};
        }
        if (!objeto._id) {
          // Insertar
          objeto._id = metodos.uuid();
          console.warn("Asignando uuid", objeto._id);
          datos.cache[modelo][objeto._id] = objeto;
          resolve(objeto);
          axios
            .post(ruta, objeto, {
              headers: {
                "wst-appkey": "vueonic",
                token: metodos.token(),
              },
            })
            .then(
              function (o) {
                console.log("Insertado", o.data.objeto);
                //resolve(o.data.objeto);
              },
              function (e) {
                console.error(e);
              }
            );
        } else {
          // Actualizar
          ruta += "/" + objeto._id;
          datos.cache[modelo][objeto._id] = objeto;
          resolve(objeto);
          axios
            .put(ruta, objeto, {
              headers: {
                "wst-appkey": "vueonic",
                token: metodos.token(),
              },
            })
            .then(
              function (o) {
                console.log("Actualizado", o.data);
                //resolve(o.data.objeto);
              },
              function (e) {
                //reject(e);
                setTimeout(function () {
                  metodos.save(modelo, objeto); // Hazlo eternamente hasta que lo PINCHES GUARDES
                }, 2000);
              }
            );
        }
      }
    });
  },
  delete(modelo, id) {
    modelo = modelo || "";
    id = id || null;
    const ruta = datos.servidor + "/" + modelo + "/" + id;
    if (datos.cache && datos.cache[modelo] && datos.cache[modelo][id])
      delete datos.cache[modelo][id];
    metodos.deleteCache(modelo, id);
    return axios.delete(ruta, {
      headers: {
        "wst-appkey": "vueonic",
        token: metodos.token(),
      },
    });
  },
  deleteMulti(modelo, lista) {
    lista = lista || [];
    modelo = modelo || "";
    const ruta =
      datos.servidor + "/multi/" + modelo + "/" + JSON.stringify(lista);
    lista.forEach(function (id) {
      metodos.deleteCache(modelo, id);
    });
    return axios.delete(ruta, {
      headers: {
        "wst-appkey": "vueonic",
        token: metodos.token(),
      },
    });
  },

  // Upload y download
  upload(handler, devolver) {
    devolver = devolver || "";
    return new Promise((resolve, reject) => {
      if (["", "binario", "base64"].indexOf(devolver) == -1)
        reject('Valor de devolución no valido ("", binario o base64)');
      const identificador = "#" + handler;
      let h = document.querySelector(identificador).files[0];
      console.log("Cargando archivo del identificador", identificador);
      let fr = new FileReader();
      if (devolver == "base64") fr.readAsDataURL(h);
      // if(devolver=='' || devolver=='binario')
      else fr.readAsBinaryString(h);
      fr.onloadend = function () {
        let binario = fr.result;
        console.log("Bin", handler, binario.length);
        const ruta = datos.servidor + "/upload";
        if (devolver != "") resolve(binario);
        else
          axios
            .post(
              ruta,
              {
                archivo: btoa(binario),
                tipo: h.type,
                nombre: h.name,
              },
              {
                headers: {
                  "wst-appkey": "vueonic",
                  token: metodos.token(),
                },
              }
            )
            .then(
              (o) => {
                resolve(o.data.filename);
              },
              (e) => {
                reject(e);
              }
            );
      };
      console.info("FileReader", h);
    });
  },
  download(str) {
    const ruta =
      str.indexOf("http") >= 0 ? datos.servidor + "/download/" + str : str;
    return axios.get(ruta, {
      headers: {
        "wst-appkey": "vueonic",
        token: metodos.token(),
      },
    });
  },
  downloadBase64(ruta) {
    return new Promise((resolve, reject) => {
      metodos.download(ruta).then(
        (obj) => {
          const header = "data:application/binary;base64,";
          resolve(header + obj.data.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  downloadForce(ruta) {
    metodos.downloadBase64(ruta).then((blob) => {
      let a = document.createElement("a");
      a.href = blob;
      a.download = ruta;
      a.style.display = "none";
      a.style.visibility = "hidden";
      document.querySelector("body").appendChild(a);
      a.click();
    });
  },
  // Rotator
  rotator(foto) {
    return new Promise((resolve, reject) => {
      if (foto.indexOf(datos.cdn) >= 0)
        foto = foto.replace(datos.cdn + "/", "");
      console.log("Pidiendo rotación de", foto);
      if (!foto || foto == "")
        reject({
          error: "No fue especificada la foto a girar",
        });
      axios
        .post(
          datos.servidor + "/rotator",
          {
            img: foto,
          },
          {
            headers: {
              "wst-appkey": "vueonic",
              token: metodos.token(),
            },
          }
        )
        .then((p) => {
          if (p && p.data && p.data.nombre) resolve(p.data.nombre);
          else
            reject({
              error: "No se pudo obtener la nueva imagen rotada",
              raw: p,
            });
        })
        .catch((e) => {
          reject({
            error: "No se pudo recuperar la imagen rotada",
            raw: e,
          });
        });
    });
  },

  // Thumbnailer
  thumbnailer(handler, ratio, nombre, tamano) {
    //devolver = devolver || ''
    const devolver = "";
    ratio = ratio || 1;
    nombre = nombre || "";
    tamano = tamano || 500;
    return new Promise((resolve, reject) => {
      if (["", "binario", "base64"].indexOf(devolver) == -1)
        reject('Valor de devolución no valido ("", binario o base64)');
      const identificador = "#" + handler;
      let h = document.querySelector(identificador).files[0];
      console.log("Cargando archivo del identificador", identificador);
      let fr = new FileReader();
      if (devolver == "base64") fr.readAsDataURL(h);
      // if(devolver=='' || devolver=='binario')
      else fr.readAsBinaryString(h);
      fr.onloadend = function () {
        let binario = fr.result;
        console.log("Bin", handler, binario.length);
        let ruta = datos.servidor + "/thumbnailer";
        if (devolver != "") resolve(binario);
        else
          axios
            .post(
              ruta,
              {
                imagen: btoa(binario),
                tipo: h.type,
                nombre: nombre || h.name,
                tamano: tamano,
                ratio: ratio,
              },
              {
                headers: {
                  "wst-appkey": "vueonic",
                  token: metodos.token(),
                },
              }
            )
            .then(
              (o) => {
                console.log("Thumbnailer?", o.data);
                resolve(o.data.url);
              },
              (e) => {
                reject(e);
              }
            );
      };
      console.info("FileReader", h);
    });
  },

  // Email
  email(obj) {
    console.log("Enviando correo", obj);
    return new Promise((resolve, reject) => {
      if (
        !obj.emailOrigen ||
        !obj.nombreOrigen ||
        !obj.mensaje ||
        !obj.emailDestino
      )
        reject({
          error: "Faltan datos",
          raw: "emailOrigen,nombreOrigen,mensaje,emailDestino",
        });
      else
        try {
          const ruta = datos.servidor + "/email";
          axios
            .post(ruta, obj, {
              headers: {
                "wst-appkey": "vueonic",
                token: metodos.token(),
              },
            })
            .then((r) => {
              resolve(r);
            });
        } catch (e) {
          console.error("ECORREO", e);
          reject({
            error: "No se pudo enviar el correo",
            raw: e,
          });
        }
    });
  },

  // Funciones de cache
  saveCache(modelo, obj) {
    if (modelo && obj) {
      console.log("Save cache", modelo, obj._id || "");
      if (datos.tiempoCache != 0 && modelo && obj._id && obj.fecha) {
        if (
          obj.fecha >= new Date().getTime() - datos.tiempoCache || // Está en rango de tiempo
          datos.WSTcolecciones.indexOf(modelo) != -1 // La app requiere que sea cacheable
        ) {
          if (!datos.cache[modelo]) datos.cache[modelo] = {};
          datos.cache[modelo][obj._id] = obj;
        }
      }
    }
  },
  deleteCache(modelo, id) {
    if (modelo && id && datos.cache[modelo] && datos.cache[modelo][id])
      delete datos.cache[modelo][id];
  },
};
export default { ...datos, ...metodos };
</script>
