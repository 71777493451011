<template>
  <ion-app>
    <ion-split-pane content-id="main-content" when="lg">
      <ion-menu content-id="main-content" type="overlay" v-if="yo?._id">
        <ion-content :color="color">
          <ion-row>
            <ion-col></ion-col>
            <ion-col size="6">
              <ion-img src="logo-blanco.svg"></ion-img>
            </ion-col>
            <ion-col></ion-col>
          </ion-row>
          <div>
            <!--ion-list-header>{{ yo.nombre }}</ion-list-header>
            <ion-note>{{ yo.email }}</ion-note-->

            <ion-item lines="none" :color="color">
              <ion-label text-wrap>
                <h2>
                  {{ yo.nombre.split(" ").splice(0, 2).join(" ") }}
                </h2>
                <p>{{ yo.email }}</p>
              </ion-label>
              <ion-button @click="logout" slot="end" fill="outline">
                <ion-icon :icon="logOut"></ion-icon>
              </ion-button>
            </ion-item>

            <ion-menu-toggle
              auto-hide="false"
              v-for="(p, i) in links.filter((l) => l.mostrar)"
              :key="i"
            >
              <ion-item
                @click="selectedIndex = i"
                router-direction="root"
                :router-link="p.link"
                lines="none"
                detail="false"
                class="hydrated"
                :color="color"
              >
                <ion-icon
                  aria-hidden="true"
                  slot="start"
                  :icon="p.icono"
                  v-if="p.icono"
                />
                <ion-label text-wrap>{{ p.label }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>
          <!--pre v-if="!modoProduccion">{{ yo }}</pre-->
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content" />
    </ion-split-pane>
  </ion-app>
</template>
<style>
.menufondo {
  background-image: url("../public/fondo_azul2.png");
  background-position: center;
  background-size: cover;
  opacity: 0.03;
  display: block;
  float: left;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 0px;
  z-index: 10;
}
.menuwrapper {
  display: block;
  float: left;
  position: absolute;
  z-index: 20;
  padding-right: 8px;
  border-radius: 10px;
}
</style>
<script>
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonImg,
  IonRow,
  IonCol,
  IonButton,
} from "@ionic/vue";
import {
  create,
  logOut,
  fileTrayFull,
  statsChart,
  personCircle,
  briefcase,
  fileTrayStackedOutline,
} from "ionicons/icons";
import Api from "@/components/Api";
import { defineComponent } from "vue";
const yo = Api.obtenerLocal("rvrs.yo") ?? {};
const links = [
  {
    label: "Asuntos",
    icono: fileTrayFull,
    link: "/asuntos",
    mostrar: true,
  },
  {
    label: "Nueva actividad",
    icono: create,
    link: "/actividad",
    mostrar: true,
  },
  {
    label: "Generador de reportes",
    icono: statsChart,
    link: "/reportes",
    mostrar: yo.reportaCostos,
  },
  {
    label: "",
    mostrar: true,
  },
  {
    label: "Abogados",
    icono: briefcase,
    link: "/catalogos/usuarios",
    mostrar: yo.editaUsuarios,
  },
  {
    label: "Clientes",
    icono: personCircle,
    link: "/catalogos/clientes",
    mostrar: yo.agregaClientes,
  },
  {
    label: "Áreas de práctica",
    icono: fileTrayStackedOutline,
    link: "/catalogos/areas",
    mostrar: yo.editaUsuarios,
  },
];
export default defineComponent({
  name: "RvrsApp",
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    IonImg,
    IonRow,
    IonCol,
    IonButton,
  },
  data() {
    return {
      yo,
      modoProduccion: Api.modoProduccion,
      links,
      logOut,
      color: "tertiary",
    };
  },
  mounted() {
    console.log("Hola", yo.nombre || "extraño");
    if (!yo._id) this.$router.push("/login");
  },
  methods: {
    async logout() {
      Api.borrarLocal("wst.yo");
      Api.borrarLocal("rvrs.yo");
      this.$router.push("/login");
      await Api.wait();
      window.location.reload();
    },
  },
});
</script>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
