import { createRouter, createWebHashHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/hola",
  },
  {
    path: "/folder/:id",
    component: () => import("../views/FolderPage.vue"),
  },
  {
    path: "/hola",
    component: () => import("../components/HelloWorld.vue"),
  },
  {
    path: "/login",
    component: () => import("../components/Login.vue"),
  },
  {
    path: "/asuntos",
    component: () => import("../components/Asuntos.vue"),
  },
  {
    path: "/asunto/:id",
    component: () => import("../components/Asunto.vue"),
    props: true,
  },
  {
    path: "/actividad",
    component: () => import("../components/ActividadHandler.vue"),
  },
  {
    path: "/actividad/:id",
    component: () => import("../components/ActividadHandler.vue"),
    props: true,
  },
  {
    path: "/reportes",
    component: () => import("../components/Reportes.vue"),
  },
  {
    path: "/facturable/:id",
    component: () => import("../components/Facturable.vue"),
    props: true,
  },
  {
    path: "/facturables/:ids/:fechaInicio/:fechaFin",
    component: () => import("../components/Facturable.vue"),
    props: true,
  },
  {
    path: "/catalogos/usuarios",
    component: () => import("../components/CatalogoUsuarios.vue"),
  },
  {
    path: "/catalogos/usuario/:id",
    component: () => import("../components/CatalogoUsuario.vue"),
    props: true,
  },
  {
    path: "/catalogos/clientes",
    component: () => import("../components/CatalogoClientes.vue"),
  },
  {
    path: "/catalogos/cliente/:id",
    component: () => import("../components/CatalogoCliente.vue"),
    props: true,
  },
  {
    path: "/catalogos/areas",
    component: () => import("../components/CatalogoAreas.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
